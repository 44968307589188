import { Notification } from '../../../Components/Common/Toaster';
import { WindowUtilities } from '../../Utilities/WindowUtilities';
import { AxiosDataService } from "./DataService.Axios";

export class AxiosSingletonDataService {
    static _instance: AxiosDataService;
    private constructor() { }
    public static get instance(): AxiosDataService {
        if (!AxiosSingletonDataService._instance) {
            console.log('new instance of AxiosDataService is created!!!');
            AxiosSingletonDataService._instance = new AxiosDataService(new Notification(), new WindowUtilities());
        }
        return AxiosSingletonDataService._instance;
    }
}