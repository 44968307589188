import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "react-bootstrap";
import CustomDropdown, {
  SelectOptions,
} from "../../../UIComponent/customDropdown";
import { forwardRef, useEffect, useRef, useState } from "react";
import CustomDatepicker from "../../../UIComponent/customDatepicker";
import { TYPES } from "../../../../Startup/types";
import { IEndpointUsageByEndpointService } from "../../../../Core/Services/EndPointUsage/EndpointUsageByEndpointService";
import container from "../../../../Startup/inversify.config";
import Pagination from "../../../UIComponent/Pagination";
import "../../../../styles/pagination.scss";
import { compareDatesByMonthYear, SizePerPageList } from "../../../../helper/HelperFunctions";
import { PAGESIZE } from "../../../../helper/Constants";
import { productTypes } from "../../../../helper/CustomTypes";
import { ICustomDropdownOption } from "../../../UIComponent/customDropdown/CustomDropdownWithCustomOptions";
import { IConfigurationService } from "../../../../Core/Services/EndPointUsage/ConfigurationService";
import {
  CustomCalendarIcon,
  RefreshButtonIcon,
} from "../../../../Assets/icons/IconCollection";
import { ToasterHelper } from "../../../Common/ToasterHelper";

const endPointService = container.get<IEndpointUsageByEndpointService>(
  TYPES.IEndpointUsageByEndpointService
);

const apiConfigurationService = container.get<IConfigurationService>(
  TYPES.IConfigurationService
);

export default function ApiUsageByEndpoint() {
  const today = new Date();
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(today);
  const [endDate, setEndDate] = useState<any>(today);
  const [gridData, setGridData] = useState([]);
  const [data, setApiData] = useState([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(SizePerPageList[0]);
  const [companies, setCompanies] = useState<SelectOptions[]>([]);
  const [hideInternalAccount, setHideInternalAccount] =
    useState<boolean>(false);

  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  const CustomInput = forwardRef((props: any, ref) => (
    <>
      <input {...props} ref={ref} className="commonDatepicker" />{" "}
      <CustomCalendarIcon />
    </>
  ));

  const inputRef = useRef(null);

  const onPageChange = (page: number, pageSize: number) => {
    setPageNo(page);
    setPageSize(pageSize);
    const indexOfLastItem = page * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const pagedItems = data?.slice(indexOfFirstItem, indexOfLastItem);
    setGridData(pagedItems);
    setPageCount(pagedItems.length);
  };

  const onHideInternalAccount = (isTestCompany: boolean) => {
    setHideInternalAccount(isTestCompany);
  };

  const onProductChange = (option: ICustomDropdownOption | null) => {
    var product = { label: option?.label, value: option?.value };
    setSelectedProduct(product);
    setSelectedCompany("");
  };

  const getCompany = () => {
    apiConfigurationService
      .getCompanies()
      .then(function (response: any) {
        if (response && response.data.data.length > 0) {
          setCompanies([]);
          var apiData = response.data.data.map((data: any) => {
            return { label: data.companyName, value: data.companyId };
          });
          setCompanies(apiData);
        } else {
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };


  const IsDateValid = (): boolean => {
    return compareDatesByMonthYear(startDate, endDate);
  };

  const getData = () => {    
    if (IsDateValid()) {
      setGridData([]);
      endPointService
        .Load(
          startDate,
          endDate,
          selectedProduct,
          selectedCompany,
          hideInternalAccount
        )
        .then(function (response: any) {
          if (response && response.data.length > 0) {
            setApiData(response.data);
            const startIndex = (pageNo - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const currentItems = response.data.slice(startIndex, endIndex);
            setGridData(currentItems);
            setCount(response.data.length);
            setPageCount(currentItems.length);
          } else {
            setGridData([]);
          }
        })
        .catch(function (error: any) {
          console.log(error);
        });
    } else {
      ToasterHelper.instance.warn("To Date can't be less than from date");
    }
  };

  useEffect(() => {
    getData();
    getCompany();
  }, []);

  return (
    <div style={{ width: "98%" }}>
      <>
        <Form>
          <Row className="mb-12">
            <Form.Group
              as={Col}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: "15px",
              }}
            >
              <h5>Endpoint Usage Report by Endpoint</h5>
            </Form.Group>
            <Form.Group
              as={Col}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                title="Refresh"
                variant="outlined"
                onClick={() => getData()}
              >
                <RefreshButtonIcon />
              </Button>
            </Form.Group>
          </Row>
        </Form>
        <Form style={{ paddingTop: "20px" }}>
          <Row className="mb-3">
            <Form.Group className="formColDatepicker">
              <Form.Label>
                From Date<span className="required">*</span>
              </Form.Label>
              <CustomDatepicker
                selectedDate={startDate}
                setSelectedDate={(date) => setStartDate(date)}
                className="commonDatepicker"
                dateFormat="MM/YYYY"
                showMonthYearDate={true}
                customInput={<CustomInput inputRef={inputRef} />}
              />
            </Form.Group>

            <Form.Group className="formColDatepicker">
              <Form.Label>
                To Date<span className="required">*</span>
              </Form.Label>
              <CustomDatepicker
                selectedDate={endDate}
                setSelectedDate={(date) => setEndDate(date)}
                className="commonDatepicker"
                dateFormat="MM/YYYY"
                showMonthYearDate={true}
                customInput={<CustomInput inputRef={inputRef} />}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>
                Product Type<span className="required">*</span>
              </Form.Label>
              <CustomDropdown
                id="productTypeDropdown"
                selectedOption={selectedProduct}
                setSelectedOption={onProductChange}
                options={productTypes}
                customPlaceHolder="Select Product Type"
                clearable={false}
                searchable={false}
                height={38}
                className="commonDropdown"
              />
            </Form.Group>

            <Form.Group as={Col} className="formColApi">
              <Form.Label>
                Company<span className="required">*</span>
              </Form.Label>
              <CustomDropdown
                id="companyDropdown"
                selectedOption={selectedCompany}
                setSelectedOption={(option) => setSelectedCompany(option)}
                options={companies}
                customPlaceHolder="Select Company"
                clearable={false}
                searchable={true}
                height={38}
                scrollableMenu={true}
                className="commonDropdown"
              />
            </Form.Group>

            <Form.Group as={Col}>
              <br />

              <Form.Check
                inline
                type={"checkbox"}
                label={"Hide Internal Use Accounts"}
                id={"chkHideIAccount"}
                onChange={(e) => onHideInternalAccount(e.target.checked)}
                style={{ paddingTop: "13px" }}
              />
            </Form.Group>
          </Row>
        </Form>

        <div>
          <table className="table usersListTable">
            <thead>
              <tr>
                <th className="userGroupsCol" scope="col">
                  Endpoint&nbsp;Name
                </th>
                <th className="userGroupsCol" scope="col">
                  Success&nbsp;Count&nbsp;
                </th>
                <th className="userGroupsCol" scope="col">
                  Total&nbsp;Count
                </th>
              </tr>
            </thead>
            <tbody>
              {gridData === undefined || gridData?.length === 0 ? (
                <tr>
                  <td
                    data-toggle="collapse"
                    colSpan={4}
                    className="usersLoadingArea text-center"
                  >
                    No matching records found
                  </td>
                </tr>
              ) : (
                gridData?.map((dat: any) => {
                  return (
                    <tr key={dat.companyId} className="userRow">
                      <td className="nameColData" title={dat.operationName}>
                        {dat.operationName}{" "}
                      </td>
                      <td>{dat.successCount}</td>
                      <td>{dat.totalCount}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div>
          {gridData && gridData?.length > 0 && (
            <Pagination
              totalCount={count}
              pageIndex={pageNo}
              pageSize={pageSize}
              defaultPageSize={PAGESIZE}
              goToPage={onPageChange}
              sizePerPageList={SizePerPageList}
              showSizePerPageList={true}
              showGoToPages={true}
              showpageCount={true}
              ShowListAndGoto={true}
              pageResultCount={pageCount}
            />
          )}
        </div>
      </>
    </div>
  );
}
