import { SelectOptions } from "../Components/UIComponent/customDropdown";

export const productTypes: SelectOptions[] = [
    { label: 'Select Product Type', value: "0" },
    { label: 'Returns', value: "1" },
	{ label: 'SignaturesOneOff', value: "2" },
	{ label: 'Organizers', value: "4" },
    { label: 'Exchange', value: "6" },
    { label: 'SSSuitePortal', value: "1001" },
];
