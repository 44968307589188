import React, { useEffect, useState } from "react";
import { FC } from "react";
import '../../styles/UsersListTable.style.scss';
import Drawer from '@mui/material/Drawer';
import Box from "@mui/material/Box";
import { Button, Form } from "react-bootstrap";
import container from '../../Startup/inversify.config';
import { ICompanyPolicyService } from "../../Core/Services/Policy/CompanyPolicyService";
import { TYPES } from "../../Startup/types";
import Pagination from "../UIComponent/Pagination";
import { SizePerPageList } from "../../helper/HelperFunctions";
import { PAGESIZE } from "../../helper/Constants";
import { EditPencil } from "../../Assets/icons/IconCollection";
import { ToasterHelper } from "../Common/ToasterHelper"

interface RateLimitTableProps {
    data :any,
    refresh(pageNo:number, PageSize:number) : void,
    onPageChange(pageNo:number, PageSize:number) : void,
    count:number,
    pageNo: number,
    pageSize: number
}
const companyPolicyService = container.get<ICompanyPolicyService>(TYPES.ICompanyPolicyService);


let RateLimitTable: FC<RateLimitTableProps> = (props) => 
    {

    const { data } = props;
    const [open, setOpen] = React.useState(false);
    const [companyData, setCompanyData] =React.useState<any>({});
    const [companyPolicy, setcompanyPolicy] = React.useState<any>({});
    const [selectedRadio , setSelectedRadio] = React.useState<String>();
    const [hasApiAccess, setHasApiAccess] = React.useState<boolean>(true);

    const toggleDrawer = (newOpen: boolean) => () => { 
        setOpen(newOpen); 
        };

    const handleEditButtonClick = (dat: any) => {
        console.log(dat);

        //Setting apiaccess to True untill full implmentation in place
        dat.isAPIAccessEnabled = true;
        
        setCompanyData(dat);
        setOpen(true);
        setSelectedRadio(dat.productId);
        setHasApiAccess(true);
    };

    const onRadioSelect = (selection:any) =>
    {
        setSelectedRadio(selection);
    };

    const onAPIAccessSelect = (selection:any) =>
        {
            console.log(selection);
            setHasApiAccess(selection);
        }

    const getCompanyPolicy = () =>
            {
                companyPolicyService.Load().then(function (response: any) {
                    setcompanyPolicy(response.data);
                })
                .catch(function (error: any) {

                });
            };

            const updateCompany = () =>
            {
                let tempCompanyData =  {...companyData, productId: selectedRadio};
                companyPolicyService.Update(tempCompanyData).then(function (response: any) {
                    ToasterHelper.instance.success(response.data.message);
                    props.refresh(props.pageNo,props.pageSize);
                })
                .catch(function (error: any) {
                    
                });
            }

            const onPageChange = (page: number, pageSize: number) => {
                props.onPageChange(page,pageSize);
            }

    useEffect(()=> 
        {
            getCompanyPolicy();
        },[]);
 

    return (
        <>
        <div style={{ overflowY : "scroll", height:"398px"}}>
        <table className='table usersListTable'>        
        <thead>
            <tr>
                <th
                    className='nameCol'
                    scope='col'>
                    Company&nbsp;Name
                </th>
                <th
                    className='emailCol'
                    scope='col'>
                    Rate&nbsp;Limit&nbsp;Tier
                </th>
                <th
                    className='userGroupsCol'
                    scope='col'>
                    API&nbsp;Access
                </th>
                <th
                    className='officeLocationsCol'
                    scope='col'>
                    Actions
                </th>                
            </tr>
        </thead>
        
        <tbody>
        {data== null || data.length === 0 ?  (<tr> <td data-toggle='collapse' colSpan={4} className='usersLoadingArea text-center'>
                                    No matching records found
                                </td>
                            </tr>) :
                            (data.map((dat:any) => 
                            { 
                                return (
                                    <tr key={dat.companyId} className='userRow'>
                                        <td  className="nameColData" title={dat.companyName}>{dat.companyName} </td>
                                        <td >{dat.policyName}</td>
                                        <td >true</td>
                                        <td className="actionsColData" >
                                            <div className='alignActionButtons'>
                                            <div>
											<button
												data-test-auto={`3cfb242a-38b9-4835-ba3c-507d9e69c162${dat.companyId}`}
												className='editUserButton'
												onClick={() => handleEditButtonClick(dat)}>
												 <EditPencil /> Edit
											</button>
										</div>
                                            </div>
                                        </td>
                                    </tr>
                                        )
                            }))
                        }
        </tbody>
        </table>
        </div>
        <div>
        { data && data.length > 0  && <Pagination
            totalCount={props.count}
            pageIndex={props.pageNo}
            pageSize={props.pageSize}
            defaultPageSize={PAGESIZE}
            goToPage={onPageChange}
            sizePerPageList={SizePerPageList}
            showSizePerPageList={true}
            showGoToPages={true}
            showpageCount={false}
            ShowListAndGoto={true}
            pageResultCount={0}
        />}
    </div>

        <Drawer 
        open={open} 
        onClose={toggleDrawer(false)} 
        anchor={"right"}
        sx={{ display: 'flex' }}>
        <Box 
            component="div"  
            sx={{ 
                    padding : "90px 54px 2px 54px",
                    flexGrow: 1
                }} 
            role="presentation">
           <h5><b>Company Details</b></h5>
           <hr/>
           <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="company-details-popup-lable">Name</Form.Label>
        <br/>
        <Form.Label>{companyData.companyName}</Form.Label>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="company-details-popup-lable" >Rate Limit Tier</Form.Label>
        <br/>
        {companyPolicy && companyPolicy.data && companyPolicy.data.map((policy : any) =>
        {
            return  <Form.Check
            style={{ fontWeight: (policy.productId === selectedRadio ? '700' : '400')}}
            inline
            type={'radio'}
            label={`${policy.policyName}`}
            id={`disabled-default-${policy.id}`}
            checked={policy.productId === selectedRadio}
            onChange={()=> onRadioSelect(policy.productId)}
          />
        })}

      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label class="company-details-popup-lable">API Access</Form.Label>
        <br/>
        <Form.Check            
            style={{ fontWeight: ( hasApiAccess ? '700' : '400')}}
            inline
            type={'radio'}
            label={'Enabled'}
            id={`disabled-default-${'Enabled'}`}
            checked={hasApiAccess}
            onChange={()=> onAPIAccessSelect(!hasApiAccess)}
            disabled = {true}
          />
        <Form.Check
            inline
            type={'radio'}
            label={'Disabled'}
            id={`disabled-default-${'Disabled'}`}
            checked={!hasApiAccess}
            onChange={()=> onAPIAccessSelect(!hasApiAccess)}
            disabled = {true}
          />
      </Form.Group>
      <Form.Group>
           <footer style={{ position : "absolute",  bottom: 0, paddingBottom : "60px", right:"54px" }} >
           <Button className="me-2" variant ="light" onClick={()=> setOpen(false)} >Cancel</Button> 
           <Button variant="primary" onClick={()=> { setOpen(false); updateCompany(); }} >Update</Button>
           </footer>
    </Form.Group>
    </Form>
        </Box>
      </Drawer>
     
        </>
        );
    };

RateLimitTable = React.memo(RateLimitTable);
export default RateLimitTable;


