import { UserMenuService } from '../Core/Services/UserMenu/UserMenuService';
import { UserService } from '../Core/Services/User/UserService';
import { ReactDataService } from '../Core/Services/DataAccess/DataService.React';
import { LocalStorageReader } from '../Core/Utilities/LocalStorageReader';
import { CompanyPolicyService } from '../Core/Services/Policy/CompanyPolicyService';
import { ProductSubscriptionService } from '../Core/Services/Subscription/ProductSubscription';
import { EndpointUsageByEndpointService } from '../Core/Services/EndPointUsage/EndpointUsageByEndpointService';
import { EndpointUsageByCompanyService } from '../Core/Services/EndPointUsage/EndpointUsageByCompanyService';
import { ConfigurationService } from '../Core/Services/EndPointUsage/ConfigurationService';
import { AxiosSingletonDataService } from '../Core/Services/DataAccess/DataServiceHelper';

export class ServiceFactory {
    private _env: string;

    constructor() {

        this._env = "SERVER";

    }

    public CreateLocalStorageReader(): any {

        this._env = "SERVER";

        switch (this._env) {

            case "TEST":
                return new LocalStorageReader(this.CreateDataService());

            case "SERVER":
                return new LocalStorageReader(this.CreateDataService());

            default:
                throw new Error("Method not implemented.");
        }

    }

    public CreateDataService(): any {

        switch (this._env) {

            case "TEST":
                return new ReactDataService();

            case "SERVER":
                return AxiosSingletonDataService.instance;

            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateUserMenuService(): any {

        switch (this._env) {
            case "TEST":
                return new UserMenuService(this.CreateDataService());
            case "SERVER":
                return new UserMenuService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateCompanyPolicyService(): any {

        switch (this._env) {
            case "TEST":
                return new CompanyPolicyService(this.CreateDataService());
            case "SERVER":
                return new CompanyPolicyService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateProductSubscriptionService(): any {

        switch (this._env) {
            case "TEST":
                return new ProductSubscriptionService(this.CreateDataService());
            case "SERVER":
                return new ProductSubscriptionService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateEndpointUsageByEndpointService(): any {

        switch (this._env) {
            case "TEST":
                return new EndpointUsageByEndpointService(this.CreateDataService());
            case "SERVER":
                return new EndpointUsageByEndpointService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateEndpointUsageByCompanyService(): any {

        switch (this._env) {
            case "TEST":
                return new EndpointUsageByCompanyService(this.CreateDataService());
            case "SERVER":
                return new EndpointUsageByCompanyService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }


    public CreateUserService(): any {

        switch (this._env) {
            case "TEST":
                return new UserService(this.CreateDataService(), this.CreateLocalStorageReader());
            case "SERVER":
                return new UserService(this.CreateDataService(), this.CreateLocalStorageReader());
            default:
                throw new Error("Method not implemented.");
        }
    }

    
    public CreateConfigurationService(): any {

        switch (this._env) {
            case "TEST":
                return new ConfigurationService(this.CreateDataService());
            case "SERVER":
                return new ConfigurationService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }
}