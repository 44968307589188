import { IDataService } from "../DataAccess/Abstraction/IDataService";
import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';
import moment from 'moment';
import { SelectOptions } from "../../../Components/UIComponent/customDropdown";


export interface IEndpointUsageByCompanyService {
    Load(fromDate:Date, toDate:Date, productType : SelectOptions, apiName : SelectOptions):any;
}


@injectable()
export class EndpointUsageByCompanyService implements IEndpointUsageByCompanyService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.BASE_URL, 100);
    }

    Load(fromDate:Date, toDate:Date, productType : SelectOptions, apiName : SelectOptions): any {
        const fDate = fromDate==null?"01/01/0001": moment(fromDate).format('MM/DD/YYYY');
        const tDate = toDate==null?"01/01/0001":moment(toDate).format('MM/DD/YYYY');
        return this._dataService.get(`reports/company?productType=${productType?.value??0}&apiName=${apiName?.label??""}&fromDate=${fDate??null}&toDate=${tDate??null}`);
    }
}

