import { IDataService } from "../DataAccess/Abstraction/IDataService";
import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';

export interface ICompanyPolicyService {
    Load(): any;
    Update(data:any) : any
} 

@injectable()
export class CompanyPolicyService implements ICompanyPolicyService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.BASE_URL, 100);
    }

    Load(): any {
        return this._dataService.getPaged(1, 'Policy/all');
    }

    Update(data:any): any {
        var updateData =  { 
            companyId : data.companyId,
            productId : data.productId,
            aPIAccess : data.isAPIAccessEnabled
        };
    return this._dataService.postJson(updateData, 'ProductSubscription');
    }
}