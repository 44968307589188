import React from "react";

export interface IconImageProps {
  height?: number;
  width?: number;
  color?: string;
  disabled?: boolean;
}

export interface IconImagePropsExt extends IconImageProps {
  menuName: SideMenus;
}

export const PaginationForwordDoubleArrow: React.FC<IconImageProps> = (
  props
) => {
  return (
    <svg
      width="12"
      height="14"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 450 512"
      fill="currentColor"
    >
      <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
    </svg>
  );
};

export const PaginationBackDoubleArrow: React.FC<IconImageProps> = (props) => {
  return (
    <svg
      width="12"
      height="14"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      fill="currentColor"
    >
      <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z" />
    </svg>
  );
};

export const PaginationForwardSingalArrow: React.FC<IconImageProps> = (
  props
) => {
  return (
    <svg
      width="12"
      height="14"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      fill="currentColor"
    >
      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
    </svg>
  );
};

export const PaginationBackSingalArrow: React.FC<IconImageProps> = (props) => {
  return (
    <svg
      width="12"
      height="14"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      fill="currentColor"
    >
      <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
    </svg>
  );
};

export const SortInitial: React.FC<IconImageProps> = () => {
  return (
    <svg
      className="caret-position"
      fill="#6B7075"
      xmlns="http://www.w3.org/2000/svg"
      height={12}
      width={12}
      viewBox="0 0 320 512"
    >
      <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z" />
    </svg>
  );
};

export const SortDescending: React.FC<IconImageProps> = () => {
  return (
    <svg
      className="caret-position"
      fill="#11294C"
      xmlns="http://www.w3.org/2000/svg"
      height={12}
      width={12}
      viewBox="0 0 320 512"
    >
      <path d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z" />
    </svg>
  );
};

export const SortAscending: React.FC<IconImageProps> = () => {
  return (
    <svg
      className="caret-position"
      fill="#11294C"
      xmlns="http://www.w3.org/2000/svg"
      height={12}
      width={12}
      viewBox="0 0 320 512"
    >
      <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
    </svg>
  );
};

export const RateLimitMenu: React.FC<IconImageProps> = () => {
  return (
    <svg
      width="32"
      height="25"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="16 7 16 16"
    >
      <path
        d="M24.4996 11.4992C24.7413 11.4992 24.9371 11.6951 24.9371 11.9367V13.2492C24.9371 13.4908 24.7413 13.6867 24.4996 13.6867C24.258 13.6867 24.0621 13.4908 24.0621 13.2492V11.9367C24.0621 11.6951 24.258 11.4992 24.4996 11.4992Z"
        fill="#212529"
      ></path>
      <path
        d="M20.7653 13.0149C20.9362 12.8441 21.2132 12.8441 21.3841 13.0149L22.184 13.8148C22.3548 13.9857 22.3548 14.2627 22.184 14.4336C22.0131 14.6044 21.7361 14.6044 21.5653 14.4336L20.7653 13.6336C20.5945 13.4628 20.5945 13.1858 20.7653 13.0149Z"
        fill="#212529"
      ></path>
      <path
        d="M19.2496 16.7492C19.2496 16.5076 19.4455 16.3117 19.6871 16.3117H21.0747C21.3163 16.3117 21.5122 16.5076 21.5122 16.7492C21.5122 16.9908 21.3163 17.1867 21.0747 17.1867H19.6871C19.4455 17.1867 19.2496 16.9908 19.2496 16.7492Z"
        fill="#212529"
      ></path>
      <path
        d="M27.5621 16.7492C27.5621 16.5076 27.758 16.3117 27.9996 16.3117H29.3121C29.5538 16.3117 29.7496 16.5076 29.7496 16.7492C29.7496 16.9908 29.5538 17.1867 29.3121 17.1867H27.9996C27.758 17.1867 27.5621 16.9908 27.5621 16.7492Z"
        fill="#212529"
      ></path>
      <path
        d="M28.2222 13.0342C28.0966 12.9086 27.8956 12.9008 27.7607 13.0162L24.1035 16.1459C23.751 16.4476 23.7302 16.9854 24.0582 17.3135C24.3938 17.649 24.9466 17.6182 25.2428 17.2475L28.2474 13.4868C28.3555 13.3515 28.3446 13.1566 28.2222 13.0342Z"
        fill="#212529"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4996 16.7492C17.4996 12.8832 20.6336 9.74921 24.4996 9.74921C28.3656 9.74921 31.4996 12.8832 31.4996 16.7492C31.4996 17.5648 31.36 18.3487 31.1029 19.0778C30.7162 20.1743 29.4892 20.4797 28.5375 20.1713C27.3952 19.8011 25.7895 19.3742 24.4996 19.3742C23.2098 19.3742 21.6041 19.8011 20.4618 20.1713C19.5101 20.4797 18.2831 20.1743 17.8964 19.0778C17.6393 18.3487 17.4996 17.5648 17.4996 16.7492ZM24.4996 10.6242C21.1169 10.6242 18.3746 13.3665 18.3746 16.7492C18.3746 17.4643 18.497 18.1499 18.7216 18.7868C18.8992 19.2906 19.5295 19.5536 20.192 19.3389C21.3462 18.9649 23.0632 18.4992 24.4996 18.4992C25.936 18.4992 27.653 18.9649 28.8072 19.3389C29.4698 19.5536 30.1 19.2906 30.2777 18.7868C30.5023 18.1499 30.6246 17.4643 30.6246 16.7492C30.6246 13.3665 27.8824 10.6242 24.4996 10.6242Z"
        fill="#212529"
      ></path>
    </svg>
  );
};

export const OpenPlug: React.FC<IconImageProps> = () => {
  return (
    <svg
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      height="25"
      viewBox="16 7 16 16"
    >
      <path
        d="M22.7496 7.99921C22.8657 7.99921 22.9769 8.0453 23.059 8.12735C23.141 8.20939 23.1871 8.32067 23.1871 8.43671V10.6242H25.8121V8.43671C25.8121 8.32067 25.8582 8.20939 25.9403 8.12735C26.0223 8.0453 26.1336 7.99921 26.2496 7.99921C26.3657 7.99921 26.4769 8.0453 26.559 8.12735C26.641 8.20939 26.6871 8.32067 26.6871 8.43671V10.6242H27.5621C27.6782 10.6242 27.7894 10.6703 27.8715 10.7523C27.9535 10.8344 27.9996 10.9457 27.9996 11.0617V13.6867C27.9996 14.4989 27.677 15.2779 27.1026 15.8522C26.5283 16.4266 25.7494 16.7492 24.9371 16.7492C24.9354 17.129 24.9284 17.4886 24.9021 17.8167C24.8663 18.2665 24.7919 18.6943 24.6248 19.0627C24.4553 19.4523 24.1551 19.7705 23.776 19.9622C23.384 20.1617 22.8975 20.2492 22.3121 20.2492C21.4389 20.2492 20.9034 20.538 20.5849 20.8775C20.2973 21.1811 20.1332 21.5811 20.1246 21.9992H19.2496C19.2496 21.4602 19.4526 20.8031 19.947 20.2772C20.4519 19.7417 21.228 19.3742 22.3121 19.3742C22.8205 19.3742 23.1539 19.2972 23.3779 19.1826C23.5888 19.075 23.7279 18.9201 23.8276 18.7013C23.9335 18.4686 23.9965 18.158 24.0289 17.7476C24.0525 17.4501 24.0595 17.1193 24.0613 16.7492C23.2492 16.749 22.4705 16.4262 21.8963 15.8519C21.3222 15.2776 20.9996 14.4988 20.9996 13.6867V11.0617C20.9996 10.9457 21.0457 10.8344 21.1278 10.7523C21.2098 10.6703 21.3211 10.6242 21.4371 10.6242H22.3121V8.43671C22.3121 8.32067 22.3582 8.20939 22.4403 8.12735C22.5223 8.0453 22.6336 7.99921 22.7496 7.99921ZM21.8746 11.4992V13.6867C21.8746 14.2669 22.1051 14.8233 22.5153 15.2335C22.9256 15.6437 23.482 15.8742 24.0621 15.8742H24.9371C25.5173 15.8742 26.0737 15.6437 26.4839 15.2335C26.8942 14.8233 27.1246 14.2669 27.1246 13.6867V11.4992H21.8746Z"
        fill="#212529"
      ></path>
    </svg>
  );
};

export const SpinnerFidget: React.FC<IconImageProps> = () => {
  return (
    <svg
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      height="25"
      viewBox="16 7 16 16"
    >
      <path
        d="M22.5915 14.3118C20.8361 12.8506 20.8834 10.762 21.8475 9.44136C22.8754 8.03198 24.7824 7.58738 26.317 8.41244C27.798 9.20851 28.5412 11.0265 27.9724 12.5079C27.7849 12.4543 27.5956 12.3998 27.3607 12.333C27.5816 11.3199 27.4221 10.3921 26.7166 9.60918C26.2609 9.10395 25.679 8.83244 25.012 8.72612C23.8465 8.53985 22.5836 9.27177 22.106 10.4246C21.6722 11.4702 21.744 13.0201 23.552 14.1114C23.3066 14.5174 23.0612 14.9233 22.8159 15.3293C22.4846 15.8758 22.1594 16.4276 21.8168 16.9671C21.617 17.2808 21.4785 17.5268 21.7458 17.9266C22.0806 18.4266 21.8773 19.1295 21.4101 19.5451C20.9544 19.951 20.2709 20.0099 19.7512 19.6892C19.2289 19.3667 18.9721 18.7235 19.1307 18.1384C19.2946 17.5347 19.8213 17.0804 20.4277 17.1077C20.7993 17.1244 20.9956 17.0014 21.1744 16.6842C21.6222 15.8881 22.1095 15.114 22.5933 14.3118H22.5915Z"
        fill="#212529"
      ></path>
      <path
        d="M23.3259 18.1454C24.4345 18.1454 25.5493 18.1366 26.664 18.1515C26.976 18.1559 27.1776 18.1041 27.3371 17.7834C27.6175 17.2193 28.266 16.9733 28.8567 17.1288C29.4623 17.2887 29.8961 17.8475 29.9013 18.474C29.9066 19.1251 29.4737 19.6962 28.8514 19.8588C28.2713 20.0099 27.5912 19.7569 27.3292 19.2024C27.1705 18.8668 26.9637 18.8334 26.6587 18.8369C25.7596 18.8492 24.8604 18.8413 23.9324 18.8413C23.7492 19.9695 23.24 20.8789 22.269 21.4869C21.5758 21.921 20.8151 22.0739 20.015 21.9658C18.3691 21.7444 17.0555 20.3166 17.002 18.7244C16.9424 16.9601 18.0168 15.4716 19.6372 15.1184C19.6916 15.3196 19.7468 15.5235 19.8125 15.7651C18.9002 16.0709 18.2166 16.6183 17.8784 17.5224C17.6514 18.1269 17.6391 18.7411 17.8442 19.3597C18.2394 20.5512 19.4427 21.3806 20.6512 21.2849C22.1998 21.1618 23.0157 20.2138 23.3268 18.1454H23.3259Z"
        fill="#212529"
      ></path>
      <path
        d="M27.1434 15.2634C29.2852 14.7002 30.9048 15.3433 31.6839 17.0427C32.3999 18.6041 31.8644 20.4642 30.4201 21.4325C29.1038 22.3155 27.2003 22.1354 26.1031 20.9905C26.2425 20.8394 26.3844 20.6856 26.5483 20.5072C27.2336 21.0617 27.9856 21.3868 28.876 21.2559C29.5464 21.1574 30.1187 20.8684 30.5717 20.3605C31.4446 19.3808 31.5314 17.953 30.7882 16.8942C29.9302 15.672 28.6507 15.4374 26.841 16.1921C26.2977 15.208 25.7429 14.2248 25.2127 13.2284C25.0795 12.978 24.9384 12.8787 24.6448 12.8866C24.0156 12.9033 23.4626 12.4464 23.3075 11.8427C23.1471 11.2206 23.4451 10.5669 24.027 10.2603C24.5993 9.95889 25.2548 10.0731 25.6947 10.5502C26.1303 11.0221 26.1943 11.6978 25.8516 12.2425C25.7903 12.3392 25.7175 12.4279 25.6351 12.5404C26.1391 13.4507 26.6438 14.361 27.1434 15.2634Z"
        fill="#212529"
      ></path>
    </svg>
  );
};

export const EditPencil: React.FC<IconImageProps> = () => {
  return (
    <svg
      width="25"
      height="15"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M12.1456 0.145185C12.1921 0.0986216 12.2472 0.0616787 12.308 0.0364723C12.3687 0.0112658 12.4339 -0.00170898 12.4996 -0.00170898C12.5654 -0.00170898 12.6305 0.0112658 12.6913 0.0364723C12.752 0.0616787 12.8072 0.0986216 12.8536 0.145185L15.8536 3.14518C15.9002 3.19163 15.9371 3.24681 15.9623 3.30755C15.9875 3.3683 16.0005 3.43342 16.0005 3.49918C16.0005 3.56495 15.9875 3.63007 15.9623 3.69082C15.9371 3.75156 15.9002 3.80674 15.8536 3.85318L5.85362 13.8532C5.80564 13.9008 5.74849 13.9383 5.68562 13.9632L0.685623 15.9632C0.594759 15.9996 0.495219 16.0085 0.399342 15.9888C0.303466 15.9691 0.21547 15.9218 0.146264 15.8525C0.0770572 15.7833 0.0296837 15.6953 0.0100163 15.5995C-0.0096512 15.5036 -0.000747699 15.404 0.035623 15.3132L2.03562 10.3132C2.06055 10.2503 2.09797 10.1932 2.14562 10.1452L12.1456 0.145185ZM11.2066 2.49918L13.4996 4.79218L14.7926 3.49918L12.4996 1.20618L11.2066 2.49918ZM12.7926 5.49918L10.4996 3.20618L3.99962 9.70618V9.99918H4.49962C4.63223 9.99918 4.75941 10.0519 4.85318 10.1456C4.94694 10.2394 4.99962 10.3666 4.99962 10.4992V10.9992H5.49962C5.63223 10.9992 5.75941 11.0519 5.85318 11.1456C5.94694 11.2394 5.99962 11.3666 5.99962 11.4992V11.9992H6.29262L12.7926 5.49918ZM3.03162 10.6742L2.92562 10.7802L1.39762 14.6012L5.21862 13.0732L5.32462 12.9672C5.22924 12.9315 5.14702 12.8676 5.08894 12.784C5.03086 12.7004 4.9997 12.601 4.99962 12.4992V11.9992H4.49962C4.36701 11.9992 4.23984 11.9465 4.14607 11.8527C4.0523 11.759 3.99962 11.6318 3.99962 11.4992V10.9992H3.49962C3.3978 10.9991 3.29843 10.9679 3.2148 10.9099C3.13117 10.8518 3.06726 10.7696 3.03162 10.6742Z"
        fill="#05386B"
      />
    </svg>
  );
};

export const CheckCircleFill = (style?: React.CSSProperties) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#0973BA"
      xmlns="http://www.w3.org/2000/svg"
      style={style ? style : {}}
    >
      <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z" />
    </svg>
  );
};

export const CloseXButton = (style?: React.CSSProperties) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 8 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style ? style : {}}
    >
      <path d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.70711 4L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L4 4.70711L1.35355 7.35355C1.15829 7.54882 0.841709 7.54882 0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645L3.29289 4L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z" />
    </svg>
  );
};

export const WarningIcon = (style?: React.CSSProperties) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style ? style : {}}
    >
      <path d="M8.98201 1.56601C8.88302 1.39358 8.74028 1.25031 8.56821 1.15069C8.39614 1.05106 8.20084 0.998596 8.00201 0.998596C7.80318 0.998596 7.60787 1.05106 7.4358 1.15069C7.26373 1.25031 7.121 1.39358 7.02201 1.56601L0.165007 13.233C-0.291993 14.011 0.256007 15 1.14501 15H14.858C15.747 15 16.296 14.01 15.838 13.233L8.98201 1.56601ZM8.00001 5.00001C8.53501 5.00001 8.95401 5.46201 8.90001 5.99501L8.55001 9.50201C8.53825 9.63978 8.47521 9.76813 8.37336 9.86165C8.27152 9.95517 8.13828 10.0071 8.00001 10.0071C7.86173 10.0071 7.7285 9.95517 7.62665 9.86165C7.52481 9.76813 7.46177 9.63978 7.45001 9.50201L7.10001 5.99501C7.08744 5.86925 7.10135 5.74224 7.14085 5.62218C7.18035 5.50212 7.24456 5.39166 7.32935 5.29792C7.41414 5.20419 7.51762 5.12925 7.63313 5.07794C7.74864 5.02663 7.87361 5.00008 8.00001 5.00001ZM8.00201 11C8.26722 11 8.52158 11.1054 8.70911 11.2929C8.89665 11.4804 9.00201 11.7348 9.00201 12C9.00201 12.2652 8.89665 12.5196 8.70911 12.7071C8.52158 12.8947 8.26722 13 8.00201 13C7.73679 13 7.48244 12.8947 7.2949 12.7071C7.10736 12.5196 7.00201 12.2652 7.00201 12C7.00201 11.7348 7.10736 11.4804 7.2949 11.2929C7.48244 11.1054 7.73679 11 8.00201 11Z" />
    </svg>
  );
};

export const RefreshButtonIcon = (style?: React.CSSProperties) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7458 6.56061C12.7453 5.37879 12.362 4.22893 11.6532 3.28325C10.9444 2.33757 9.94832 1.64698 8.81412 1.31491C7.67992 0.982838 6.46866 1.02716 5.36176 1.44125C4.25486 1.85533 3.31191 2.61688 2.67414 3.61184C2.03637 4.60679 1.73812 5.7816 1.82404 6.96028C1.90997 8.13897 2.37545 9.2581 3.15078 10.15C3.9261 11.042 4.96953 11.6587 6.12478 11.9079C7.28003 12.1571 8.48491 12.0253 9.55896 11.5322C9.68955 11.4785 9.83582 11.4775 9.96719 11.5292C10.0985 11.5809 10.2048 11.6815 10.2637 11.8098C10.3226 11.9381 10.3297 12.0842 10.2833 12.2175C10.2369 12.3509 10.1408 12.4611 10.015 12.5252C8.72607 13.1168 7.28015 13.275 5.89382 12.9759C4.50749 12.6768 3.25538 11.9366 2.32505 10.8661C1.39471 9.79566 0.836235 8.45261 0.733266 7.03813C0.630297 5.62364 0.988383 4.21387 1.75388 3.01998C2.51938 1.82608 3.65109 0.912342 4.97949 0.415621C6.30789 -0.0810993 7.76146 -0.13406 9.12249 0.264672C10.4835 0.663404 11.6787 1.49236 12.5291 2.62738C13.3794 3.7624 13.8392 5.14238 13.8394 6.56061H12.7458Z"
        fill="#05386B"
      />
      <path
        d="M11.1425 6.56061H15.4426C15.4946 6.56063 15.5455 6.57545 15.5893 6.60333C15.6331 6.63122 15.6681 6.67101 15.6901 6.71806C15.7122 6.76511 15.7203 6.81746 15.7137 6.86899C15.7071 6.92051 15.6859 6.96907 15.6526 7.00899L13.5026 9.58994C13.3932 9.72117 13.192 9.72117 13.0826 9.58994L10.9325 7.00899C10.8993 6.96907 10.8781 6.92051 10.8715 6.86899C10.8648 6.81746 10.873 6.76511 10.895 6.71806C10.9171 6.67101 10.952 6.63122 10.9959 6.60333C11.0397 6.57545 11.0906 6.56063 11.1425 6.56061Z"
        fill="#05386B"
      />
    </svg>
  );
};

export const SearchButtonIcon = (style?: React.CSSProperties) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_791_212)">
        <rect width="16" height="16" fill="white" fill-opacity="0.01" />
        <g clip-path="url(#clip1_791_212)">
          <path
            d="M11.742 10.344C12.7103 9.02267 13.144 7.38447 12.9563 5.75713C12.7686 4.12979 11.9734 2.63332 10.7297 1.56711C9.4861 0.500899 7.88573 -0.056418 6.24882 0.00665514C4.61191 0.0697283 3.05917 0.748541 1.90125 1.90729C0.743334 3.06603 0.0656328 4.61926 0.00373101 6.25621C-0.0581708 7.89317 0.500292 9.49314 1.56739 10.736C2.63449 11.9789 4.13153 12.773 5.759 12.9596C7.38648 13.1461 9.02436 12.7112 10.345 11.742H10.344C10.374 11.782 10.406 11.82 10.442 11.857L14.292 15.707C14.4795 15.8946 14.7339 16.0001 14.9991 16.0002C15.2644 16.0003 15.5188 15.895 15.7065 15.7075C15.8941 15.52 15.9996 15.2656 15.9997 15.0003C15.9998 14.7351 15.8945 14.4806 15.707 14.293L11.857 10.443C11.8212 10.4068 11.7828 10.3734 11.742 10.343V10.344ZM12 6.49998C12 7.22225 11.8577 7.93745 11.5813 8.60474C11.3049 9.27203 10.8998 9.87834 10.3891 10.3891C9.87835 10.8998 9.27203 11.3049 8.60474 11.5813C7.93745 11.8577 7.22225 12 6.49998 12C5.77771 12 5.06251 11.8577 4.39522 11.5813C3.72793 11.3049 3.12162 10.8998 2.61089 10.3891C2.10017 9.87834 1.69505 9.27203 1.41864 8.60474C1.14224 7.93745 0.999982 7.22225 0.999982 6.49998C0.999982 5.04129 1.57945 3.64234 2.6109 2.61089C3.64235 1.57944 5.04129 0.999979 6.49998 0.999979C7.95867 0.999979 9.35762 1.57944 10.3891 2.61089C11.4205 3.64234 12 5.04129 12 6.49998Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_791_212">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_791_212">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DoubleChevronLeftIcon = (style?: React.CSSProperties) => {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.42646 0.137713C6.47012 0.181256 6.50475 0.232983 6.52838 0.289931C6.55201 0.34688 6.56418 0.407931 6.56418 0.469588C6.56418 0.531245 6.55201 0.592296 6.52838 0.649245C6.50475 0.706193 6.47012 0.75792 6.42646 0.801463L1.1324 6.09459L6.42646 11.3877C6.51448 11.4757 6.56393 11.5951 6.56393 11.7196C6.56393 11.8441 6.51448 11.9634 6.42646 12.0515C6.33844 12.1395 6.21907 12.1889 6.09459 12.1889C5.97011 12.1889 5.85073 12.1395 5.76271 12.0515L0.137713 6.42646C0.09406 6.38292 0.059426 6.33119 0.035795 6.27424C0.0121639 6.2173 0 6.15624 0 6.09459C0 6.03293 0.0121639 5.97188 0.035795 5.91493C0.059426 5.85798 0.09406 5.80626 0.137713 5.76271L5.76271 0.137713C5.80626 0.09406 5.85798 0.059426 5.91493 0.035795C5.97188 0.0121639 6.03293 0 6.09459 0C6.15624 0 6.2173 0.0121639 6.27424 0.035795C6.33119 0.059426 6.38292 0.09406 6.42646 0.137713Z"
        fill="#0973BA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1765 0.137713C10.2201 0.181256 10.2547 0.232983 10.2784 0.289931C10.302 0.34688 10.3142 0.407931 10.3142 0.469588C10.3142 0.531245 10.302 0.592296 10.2784 0.649245C10.2547 0.706193 10.2201 0.75792 10.1765 0.801463L4.8824 6.09459L10.1765 11.3877C10.22 11.4313 10.2546 11.483 10.2782 11.54C10.3018 11.5969 10.3139 11.658 10.3139 11.7196C10.3139 11.7812 10.3018 11.8423 10.2782 11.8992C10.2546 11.9561 10.22 12.0079 10.1765 12.0515C10.1329 12.095 10.0811 12.1296 10.0242 12.1532C9.96725 12.1768 9.90622 12.1889 9.84459 12.1889C9.78295 12.1889 9.72192 12.1768 9.66498 12.1532C9.60804 12.1296 9.5563 12.095 9.51271 12.0515L3.88771 6.42646C3.84406 6.38292 3.80943 6.33119 3.78579 6.27424C3.76216 6.2173 3.75 6.15624 3.75 6.09459C3.75 6.03293 3.76216 5.97188 3.78579 5.91493C3.80943 5.85798 3.84406 5.80626 3.88771 5.76271L9.51271 0.137713C9.55626 0.09406 9.60798 0.059426 9.66493 0.035795C9.72188 0.0121639 9.78293 0 9.84459 0C9.90624 0 9.9673 0.0121639 10.0242 0.035795C10.0812 0.059426 10.1329 0.09406 10.1765 0.137713Z"
        fill="#0973BA"
      />
    </svg>
  );
};

export const DoubleChevronRightIcon = (style?: React.CSSProperties) => {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.16814 12.4569C4.12449 12.4133 4.08986 12.3616 4.06622 12.3047C4.04259 12.2477 4.03043 12.1867 4.03043 12.125C4.03043 12.0633 4.04259 12.0023 4.06622 11.9453C4.08986 11.8884 4.12449 11.8367 4.16814 11.7931L9.4622 6.5L4.16814 1.20688C4.08012 1.11886 4.03068 0.999478 4.03068 0.875001C4.03068 0.750524 4.08012 0.631144 4.16814 0.543126C4.25616 0.455107 4.37554 0.405659 4.50002 0.405659C4.6245 0.405659 4.74387 0.455107 4.83189 0.543126L10.4569 6.16813C10.5005 6.21167 10.5352 6.2634 10.5588 6.32035C10.5824 6.37729 10.5946 6.43834 10.5946 6.5C10.5946 6.56166 10.5824 6.62271 10.5588 6.67966C10.5352 6.73661 10.5005 6.78833 10.4569 6.83188L4.83189 12.4569C4.78835 12.5005 4.73662 12.5352 4.67967 12.5588C4.62272 12.5824 4.56167 12.5946 4.50002 12.5946C4.43836 12.5946 4.37731 12.5824 4.32036 12.5588C4.26341 12.5352 4.21168 12.5005 4.16814 12.4569Z"
        fill="#0973BA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.418141 12.4569C0.374489 12.4133 0.339855 12.3616 0.316224 12.3047C0.292593 12.2477 0.280429 12.1867 0.280429 12.125C0.280429 12.0633 0.292593 12.0023 0.316224 11.9453C0.339855 11.8884 0.374489 11.8367 0.418141 11.7931L5.7122 6.5L0.418142 1.20688C0.37456 1.16329 0.339989 1.11155 0.316402 1.05461C0.292815 0.997667 0.280676 0.936635 0.280676 0.875001C0.280676 0.813366 0.292815 0.752335 0.316402 0.695391C0.339989 0.638448 0.37456 0.586708 0.418142 0.543125C0.461725 0.499543 0.513465 0.464972 0.570408 0.441384C0.627352 0.417798 0.688383 0.405659 0.750018 0.405659C0.811652 0.405659 0.872685 0.417798 0.929628 0.441384C0.98657 0.464972 1.03831 0.499543 1.08189 0.543125L6.70689 6.16813C6.75055 6.21167 6.78518 6.2634 6.80881 6.32035C6.83244 6.37729 6.84461 6.43834 6.84461 6.5C6.84461 6.56166 6.83244 6.62271 6.80881 6.67966C6.78518 6.73661 6.75055 6.78833 6.70689 6.83188L1.08189 12.4569C1.03835 12.5005 0.986622 12.5352 0.929673 12.5588C0.872725 12.5824 0.811673 12.5946 0.750017 12.5946C0.68836 12.5946 0.627309 12.5824 0.57036 12.5588C0.513412 12.5352 0.461684 12.5005 0.418141 12.4569Z"
        fill="#0973BA"
      />
    </svg>
  );
};

export const CustomCalendarIcon = (style?: React.CSSProperties) => {
  return (
    <svg
      width="16"
      height="16"
      style={{ marginLeft: "-45px" }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fill-opacity="0.01" />
      <g clip-path="url(#clip0_882_1009)">
        <rect
          width="14"
          height="14"
          transform="translate(1 1)"
          fill="white"
          fill-opacity="0.01"
        />
        <g clip-path="url(#clip1_882_1009)">
          <path
            d="M10.4969 7.25196C10.5376 7.2926 10.57 7.34088 10.592 7.39403C10.6141 7.44718 10.6254 7.50416 10.6254 7.56171C10.6254 7.61925 10.6141 7.67623 10.592 7.72939C10.57 7.78254 10.5376 7.83082 10.4969 7.87146L7.8719 10.4965C7.83126 10.5372 7.78298 10.5695 7.72983 10.5916C7.67668 10.6136 7.6197 10.625 7.56215 10.625C7.5046 10.625 7.44762 10.6136 7.39447 10.5916C7.34132 10.5695 7.29304 10.5372 7.2524 10.4965L5.9399 9.18396C5.89922 9.14328 5.86696 9.09499 5.84494 9.04184C5.82293 8.9887 5.8116 8.93173 5.8116 8.87421C5.8116 8.81668 5.82293 8.75972 5.84494 8.70657C5.86696 8.65342 5.89922 8.60513 5.9399 8.56446C6.02205 8.48231 6.13347 8.43615 6.24965 8.43615C6.30718 8.43615 6.36414 8.44748 6.41728 8.4695C6.47043 8.49151 6.51872 8.52378 6.5594 8.56446L7.56215 9.56808L9.8774 7.25196C9.91804 7.21121 9.96632 7.17889 10.0195 7.15683C10.0726 7.13478 10.1296 7.12342 10.1871 7.12342C10.2447 7.12342 10.3017 7.13478 10.3548 7.15683C10.408 7.17889 10.4563 7.21121 10.4969 7.25196Z"
            fill="#565A5E"
          />
          <path
            d="M4.06215 0.999207C4.17818 0.999207 4.28946 1.0453 4.37151 1.12735C4.45356 1.20939 4.49965 1.32067 4.49965 1.43671V1.87421H11.4996V1.43671C11.4996 1.32067 11.5457 1.20939 11.6278 1.12735C11.7098 1.0453 11.8211 0.999207 11.9371 0.999207C12.0532 0.999207 12.1645 1.0453 12.2465 1.12735C12.3286 1.20939 12.3746 1.32067 12.3746 1.43671V1.87421H13.2496C13.7138 1.87421 14.1589 2.05858 14.4871 2.38677C14.8153 2.71496 14.9996 3.16008 14.9996 3.62421V13.2492C14.9996 13.7133 14.8153 14.1585 14.4871 14.4866C14.1589 14.8148 13.7138 14.9992 13.2496 14.9992H2.74965C2.28552 14.9992 1.8404 14.8148 1.51221 14.4866C1.18402 14.1585 0.999649 13.7133 0.999649 13.2492V3.62421C0.999649 3.16008 1.18402 2.71496 1.51221 2.38677C1.8404 2.05858 2.28552 1.87421 2.74965 1.87421H3.62465V1.43671C3.62465 1.32067 3.67074 1.20939 3.75279 1.12735C3.83484 1.0453 3.94612 0.999207 4.06215 0.999207ZM1.87465 4.49921V13.2492C1.87465 13.4813 1.96684 13.7038 2.13093 13.8679C2.29502 14.032 2.51758 14.1242 2.74965 14.1242H13.2496C13.4817 14.1242 13.7043 14.032 13.8684 13.8679C14.0325 13.7038 14.1246 13.4813 14.1246 13.2492V4.49921H1.87465Z"
            fill="#565A5E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_882_1009">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
        <clipPath id="clip1_882_1009">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ToasterErrorIcon = (style?: React.CSSProperties) => {
  return (<svg 
    viewBox="0 0 24 24" 
    fill="#e74c3c">
      <path d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
    </svg>);
};

export const ToasterSuccessIcon = (style?: React.CSSProperties) => {
  return ( <svg 
    viewBox="0 0 24 24" 
    fill="green">
      <path d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path>
    </svg>);
};

export const Empty = () => {
  return <></>;
};

export enum SideMenus {
  None,
  RateLimit,
  EndPointUsage,
  WebHookUsage,
}

interface SideMenuSelectorProps {
  menuName: SideMenus;
}

export const SideMenuIconSelector = (props: SideMenuSelectorProps) =>
  //let SideMenuSelector: FC<SideMenuSelectorProps> = ({menuName}) =>
  {
    const renderIcon = (menu: SideMenus | string) => {
      switch (menu) {
        case SideMenus.RateLimit:
          return <RateLimitMenu />;
        case SideMenus.EndPointUsage:
          return <OpenPlug />;
        case SideMenus.WebHookUsage:
          return <SpinnerFidget />;
        default:
          return <Empty />;
      }
    };
    return renderIcon(props.menuName);
  };
