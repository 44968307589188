
import { useEffect, useState } from "react";
import { TYPES } from "../../Startup/types";
import container from '../../Startup/inversify.config';
import { IProductSubscriptionService } from "../../Core/Services/Subscription/ProductSubscription";
import RateLimitTable from "./RateLimitTable";
import { RateLimitGridData } from "../Common/model/GridData";
import { RefreshButtonIcon, SearchButtonIcon } from "../../Assets/icons/IconCollection";
import { SizePerPageList } from "../../helper/HelperFunctions";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Form, InputGroup } from "react-bootstrap";

const productSubscriptionService = container.get<IProductSubscriptionService>(TYPES.IProductSubscriptionService);


export default function RateLimitAccess()
{ 
  const [companySubscription, setcompanySubscription] = useState<RateLimitGridData>();
  const [count,setCount]= useState<number>(0);
  const [pageNo,setPageNo]= useState<number>(1);
  const [pageSize,setPageSize]= useState<number>(SizePerPageList[0]);
  const [searchText,setSearchText] = useState<string>('');
 
  const getAllCompanySubscription = (_pageNo:number, _pageSize:number, _searchText:string = ''): void => {
    productSubscriptionService.Load(_pageNo, _pageSize, _searchText).then(function (response: any) {
      setcompanySubscription(response.data.data);
      setCount(response.data.data.totalCount);
    })
      .catch(function (error: any) {
      });
  }
  
  const onPageChange = (pageNo: number, PageSize: number): void => {
    setPageNo(pageNo);
    setPageSize(PageSize);
    getAllCompanySubscription(pageNo,PageSize,searchText);
  }

  const onProductSubscriptionUpdate = (PageNo: number, PageSize: number): void => {
    setPageNo(PageNo);
    setPageSize(PageSize);
    getAllCompanySubscription(PageNo,PageSize,searchText);
  }

  const getDefaultPageCompanySubscription = () => {
    setPageNo(1);
    setPageSize(SizePerPageList[0]);
    setSearchText('');
    getAllCompanySubscription(1,SizePerPageList[0],'');
  };

  const getFilteredPageCompanySubscription = (event: any) => {
    const _searchText = event.target.value.trim();
    if (event.key === "Enter") {
      setSearchText(_searchText);
      setPageNo(1);
      getAllCompanySubscription(1,pageSize,_searchText);
    }
  }

  const handleSearchIconClick = () => { 
    if(searchText.trim() !== ''){
      setPageNo(1);
      getAllCompanySubscription(1,pageSize,searchText);
    }
  }

  const hanldeSearchTextOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>  setSearchText(e.target.value); 

  useEffect(() => getDefaultPageCompanySubscription(), []);

  return <div className="py-3">
    <Row className="g-0 align-item-center pb-4">
      <Col className="col-8">
        <h5>Rate Limits Access</h5>
      </Col>
      <Col className="col-4">
        <InputGroup >
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            onChange={hanldeSearchTextOnChange}
            onKeyDown={getFilteredPageCompanySubscription}
            value={searchText}
          />
          <InputGroup.Text id="search-icon" onClick={handleSearchIconClick}>
            <SearchButtonIcon />
          </InputGroup.Text>
          <Button title='Refresh' variant="outlined" onClick={() => getDefaultPageCompanySubscription()} >
            <RefreshButtonIcon />
          </Button>
        </InputGroup>
      </Col>
    </Row>
    <Row className="g-0">
      <Col>
        <RateLimitTable
          data={companySubscription?.companyProductSubscriptions}
          count={count}
          pageNo={pageNo}
          pageSize={pageSize}
          refresh={onProductSubscriptionUpdate}
          onPageChange={onPageChange} />
      </Col>
    </Row>
  </div> 
}