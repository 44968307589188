import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { injectable } from 'inversify';
import { ToasterErrorIcon, ToasterSuccessIcon } from '../../Assets/icons/IconCollection'

export interface INotification {
    success(message: string): void;
    warn(message: string): void;
    error(message: string): void;
    info(message: string): void;
}

@injectable()
export class Notification implements INotification {

    success(message: string): void {
        toast(<div className='toast-message-icon-contianer'>
            <span className='toast-message-icon'><ToasterSuccessIcon></ToasterSuccessIcon></span>
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position: "top-right"
        });
    }
    warn(message: string): void {
        toast.warn(<div> 
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position: "top-right"
        });
    }
    error(message: string): void {
        toast(<div className='toast-message-icon-contianer'> 
            <span className='toast-message-icon'><ToasterErrorIcon></ToasterErrorIcon></span>
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position: "top-right"
        });
    }
    info(message: string): void {
        toast.info(<div> 
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position: "top-right"
        });
    }
}


export class Toaster extends React.Component<any, any> {
    
    render() {
        return (
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    pauseOnHover
                    className='toastcontainer'
                />
            </div>
        );
    }
}