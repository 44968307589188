import { IDataService } from "./Abstraction/IDataService";
import axios from 'axios';
import { INotification } from '../../../Components/Common/Toaster';
import { IWindowUtilities } from "../../Utilities/WindowUtilities";

export class AxiosDataService implements IDataService {

    private _baseUri: string;
    private _pageSize: number;

    constructor(notification: INotification, windowUtilities: IWindowUtilities) {

        axios.interceptors.request.use(function (config: any) {
            setTimeout(() => {
                windowUtilities.showLoader();
            });
            return config;
        }, function (error: any) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            setTimeout(() => {
                windowUtilities.hideLoader();
            });
            return response;

        }, function (error: any) {
            //withRouter()
            windowUtilities.hideLoader(true); 
            console.log('Error : ', error); 
            if (!error?.config?.__isRetryRequest) {
                document.getElementById("loader")!.classList.remove('visible');
                document.getElementById("loader")!.classList.add('invisible');
                document.body.classList.remove('loading-shading-mui');
                
                if (error?.response?.status === 403) {
                    notification.warn(
                        "Sorry, you don't have permission to view this page.Please contact administrator."
                    );
                }
                else if (error?.response?.status === 500) {
                    notification.error(
                        "Internal server error occurred.Please contact administrator"
                    );
                }
                else if (error?.response?.status === 400) {
                    notification.error(error?.response?.data?.error?.message);
                }
                else if (error?.response?.status === 422) {
                    notification.error(error?.response?.data?.error?.message);
                }
                else {
                    error?.response?.data?.map((message: any) => {
                        notification.error(message?.ErrorMessage);
                    })
                }
                
                if (error?.config?.__isRetryRequest) {
                    error.config.__isRetryRequest = true;
                }                
            }
            return Promise.reject(error);
        });
    }

    set(baseUri: string, pageSize: number) {
        this._baseUri = baseUri;
        this._pageSize = pageSize;
    }

    getPaged(page: number, uriPart?: string | undefined): any {
        return axios.get(this._baseUri + uriPart);
    }

    get(uriPart?: string | undefined, data?: any): any {
        return axios.get(this._baseUri + uriPart, { params: data, method: 'get' });
    }

    getBlob(uriPart?: string | undefined, data?: any): any {
        return axios.get(this._baseUri + uriPart, { params: data, method: 'get', responseType: 'arraybuffer' });
    }

    post(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined): any {
        return axios.post(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'multipart/form-data' }, method: 'post' });
    }

    put(data: any, uriPart?: string | undefined): any {
        return axios.put(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'multipart/form-data' }, method: 'put' });
    }

    putJson(data: any, uriPart?: string | undefined): any {
        return axios.put(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
    }

    postJson(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined): any {
        return axios.post(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
    } 
    
    delete(id: number, uriPart?: string | undefined): any {
        return axios.delete(this._baseUri + uriPart);

    }

    deleteExtended(data: any, uriPart?: any): any {
        throw new Error("Method not implemented.");
    }
}