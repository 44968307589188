import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { UserMenu } from './UserMenu';
import { Outlet } from 'react-router-dom';
import logo from '../../logo1.svg'
import Loader from './Loader';
import { msalInstance } from "../../Auth/AuthConfig";
import Button from '@mui/material/Button';
import { DoubleChevronLeftIcon, DoubleChevronRightIcon } from '../../Assets/icons/IconCollection';

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export default function RootLayout() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);


  const handleRedirect = () => {
    const allAccounts = msalInstance.getAllAccounts();
    if (allAccounts.length > 0) {
      var myAccount = allAccounts[0];
      msalInstance
        .logout({
          account: myAccount,
          postLogoutRedirectUri: "/",
        })
        .then(() => {
          console.log("Logout successful.");
        })
        .catch((error) => {
          console.error("Logout error:", error);
        });
    } else {
      msalInstance.logout();
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenClose = () => {
    setOpen(prevState => { return !prevState });
  };

  return (
    <>
    <Loader />
    <Box className='main-header'>
        <img className='logo-pointer' src={logo} />         
        <Button onClick={handleRedirect} color="primary" className='mulish-font'>Sign Out</Button>
    </Box>
     
    <Box className='main-container '>
      <Box>
        <Drawer className='menu-drawer' variant="permanent" open={open}>
          <DrawerHeader className='menu-drawer-header' >
          {open ? <Typography> Collapse </Typography> : <></>  }
            <IconButton onClick={handleDrawerOpenClose}>
              {open ?   <DoubleChevronLeftIcon /> : <DoubleChevronRightIcon />  }
            </IconButton>
          </DrawerHeader>
          <UserMenu open={open} /> 
        </Drawer>
      </Box>
      <Box className='' sx={{ flexGrow: 1, pl: "24px", pr:"25px" }}>
        <Outlet  />
      </Box>
    </Box>
    </>
  );
}