import './App.css';

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { loginRequest } from "./Auth/AuthConfig";
import AuthLoader from "./Auth/AuthLoader";
import TokenConfig from "./Auth/TokenConfig";
import { Toaster } from './Components/Common/Toaster';
import 'react-toastify/dist/ReactToastify.css';

function App(props : any ) {
  const { loginInstance } = props;
  const navigateLogin = () => {
    loginInstance
      .loginRedirect({ ...loginRequest })
      .catch((error: any) => console.error(error));
  };

  return (
    <MsalProvider instance={loginInstance}>
      <div>
        <Toaster />
        <AuthenticatedTemplate>  
          <TokenConfig msalInstance={loginInstance} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AuthLoader navigate={navigateLogin} />
        </UnauthenticatedTemplate>
      </div>
    </MsalProvider>
  );
}

export default App;
