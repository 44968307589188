

export const TYPES = {
    IUserMenuService: Symbol.for("IUserMenuService"),
    IUserService: Symbol.for("IUserService"),
    ICompanyPolicyService : Symbol.for("ICompanyPolicyService"),
    IProductSubscriptionService : Symbol.for("IProductSubscriptionService"),
    IEndpointUsageByEndpointService :Symbol.for("IEndpointUsageByEndpointService"),
    IEndpointUsageByCompanyService :Symbol.for("IEndpointUsageByCompanyService"),
    IConfigurationService :Symbol.for("IConfigurationService")
}