import container from "../../../../Startup/inversify.config";
import { TYPES } from "../../../../Startup/types";
import { forwardRef, useEffect, useRef, useState } from "react";
import { compareDatesByMonthYear, SizePerPageList } from "../../../../helper/HelperFunctions";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomDatepicker from "../../../UIComponent/customDatepicker";
import CustomDropdown, {
  SelectOptions,
} from "../../../UIComponent/customDropdown";
import { productTypes } from "../../../../helper/CustomTypes";
import Pagination from "../../../UIComponent/Pagination";
import { PAGESIZE } from "../../../../helper/Constants";
import { ICustomDropdownOption } from "../../../UIComponent/customDropdown/CustomDropdownWithCustomOptions";
import { IConfigurationService } from "../../../../Core/Services/EndPointUsage/ConfigurationService";
import { IEndpointUsageByCompanyService } from "../../../../Core/Services/EndPointUsage/EndpointUsageByCompanyService";
import "react-datepicker/dist/react-datepicker.css";
import {
  CustomCalendarIcon,
  RefreshButtonIcon,
} from "../../../../Assets/icons/IconCollection";
import { ToasterHelper } from "../../../Common/ToasterHelper";

const endPointService = container.get<IEndpointUsageByCompanyService>(
  TYPES.IEndpointUsageByCompanyService
);

const apiConfigurationService = container.get<IConfigurationService>(
  TYPES.IConfigurationService
);

export default function ApiUsageByCompany() {
  const today = new Date();
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [apiName, setApiName] = useState<any>(null);
  const [apiConfiguration, setApiConfiguration] = useState<SelectOptions[]>([]);
  const [startDate, setStartDate] = useState<any>(today);
  const [endDate, setEndDate] = useState<any>(today);
  const [gridData, setGridData] = useState([]);
  const [data, setMasterData] = useState([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(SizePerPageList[0]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [filteredData, setFilteredData] = useState([]);
  const [hideTestCompanies, setHideTestCompanies] = useState(false);

  const onPageChange = (page: number, pageSize: number) => {
    setPageNo(page);
    setPageSize(pageSize);
    const indexOfLastItem = page * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const pagedItems = hideTestCompanies ? filteredData?.slice(indexOfFirstItem, indexOfLastItem) : data?.slice(indexOfFirstItem, indexOfLastItem);
    setGridData(pagedItems);
    setPageCount(pagedItems.length);
  };

  const onHideInternalAccount = (hideTestCompany: boolean) => {
    setHideTestCompanies(hideTestCompany);
    if (hideTestCompany) {
      const filteredItems = data.filter(
        (item) => item["isTestCompany"] !== hideTestCompany
      );
      const startIndex = filteredItems.length < pageNo * pageSize ? 0 : (pageNo - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const currentItems = filteredItems.slice(startIndex, endIndex);
      setGridData(currentItems);
      setCount(filteredItems.length);
      setFilteredData(filteredItems);
      if(filteredItems.length < pageNo * pageSize){
        setPageNo(1);
      }
    } else {
      const startIndex = (pageNo -1) * pageSize;
      const endIndex = startIndex + pageSize;
      const currentItems = data.slice(startIndex, endIndex);
      setGridData(currentItems);
      setCount(data.length);
      if(data.length < pageNo * pageSize){
        setPageNo(1);
      }
    }
  };

  const onProductChange = (option: ICustomDropdownOption | null) => {
    var product = { label: option?.label, value: option?.value };
    if (option?.label !== "Select Product Type") {
      setSelectedProduct(product);
      getApiConfiguration(product?.label);
    } else {
      setSelectedProduct("Select Product Type")
      setApiName("");
      setApiConfiguration([]);
    }
  };

  const IsDateValid = (): boolean => {
    return compareDatesByMonthYear(startDate, endDate);
  };

  const refreshData = () => {
    setHideTestCompanies(false);
    getData();
  }

  const getData = () => {
    if (IsDateValid()) {
      setGridData([]);
      endPointService
        .Load(startDate, endDate, selectedProduct, apiName)
        .then(function (response: any) {
          if (response && response.data.length > 0) {
            setMasterData(response.data);
            const startIndex = (pageNo - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const currentItems = response.data.slice(startIndex, endIndex);
            setGridData(currentItems);
            setCount(response.data.length);
            setPageCount(currentItems.length);
          } else {
            setGridData(response.data);
            setMasterData(response.data);
          }
        })
        .catch(function (error: any) {
          console.log(error);
        });
    } else {
      ToasterHelper.instance.warn("To Date can't be less than from date");
    }
  };

  const getApiConfiguration = (productType: any) => {
    apiConfigurationService
      .getApiNames(productType)
      .then(function (response: any) {
        if (response && response.data.length > 0) {
          setApiConfiguration([]);
          setApiName("");
          var apiData = response.data.map((data: any) => {
            return { label: data.apiName, value: data.apiId };
          });
          setApiConfiguration(apiData);
        } else {
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const CustomInput = forwardRef((props: any, ref) => (
    <>
      <input {...props} ref={ref} className="commonDatepicker" />{" "}
      <CustomCalendarIcon />
    </>
  ));

  const inputRef = useRef(null);

  return (
    <div style={{ width: "98%" }}>
      <>
        <Form>
          <Row className="mb-12">
            <Form.Group
              as={Col}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: "15px",
              }}
            >
              <h5>Endpoint Usage Report by Company</h5>
            </Form.Group>
            <Form.Group
              as={Col}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                title="Refresh"
                variant="outlined"
                onClick={() => refreshData()}
              >
                <RefreshButtonIcon />
              </Button>
            </Form.Group>
          </Row>
        </Form>
        <Form style={{ paddingTop: "20px" }}>
          <Row className="mb-3">
            <Form.Group className="formColDatepicker">
              <Form.Label>
                From Date<span className="required">*</span>
              </Form.Label>
              <CustomDatepicker
                selectedDate={startDate}
                setSelectedDate={(date) => setStartDate(date)}
                dateFormat="MM/YYYY"
                showMonthYearDate={true}
                customInput={<CustomInput inputRef={inputRef} />}
              />
            </Form.Group>
            <Form.Group className="formColDatepicker">
              <Form.Label>
                To Date<span className="required">*</span>
              </Form.Label>
              <CustomDatepicker
                selectedDate={endDate}
                setSelectedDate={(date) => setEndDate(date)}
                dateFormat="MM/YYYY"
                showMonthYearDate={true}
                customInput={<CustomInput inputRef={inputRef} />}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>
                Product Type<span className="required">*</span>
              </Form.Label>
              <CustomDropdown
                id="productTypeDropdown"
                selectedOption={selectedProduct}
                setSelectedOption={onProductChange}
                options={productTypes}
                customPlaceHolder="Select Product Type"
                clearable={false}
                searchable={false}
                height={38}
                className="commonDropdown"
              />
            </Form.Group>

            <Form.Group as={Col} className="formColApi">
              <Form.Label>
                API<span className="required">*</span>
              </Form.Label>
              <CustomDropdown
                id="apiDropdown"
                selectedOption={apiName}
                disabled={false}
                setSelectedOption={(option) => setApiName(option)}
                options={apiConfiguration}
                customPlaceHolder="Select Api"
                clearable={false}
                searchable={false}
                height={38}
                className="commonDropdown"
              />
            </Form.Group>

            <Form.Group as={Col}>
              <br />

              <Form.Check
                inline
                type={"checkbox"}
                label={"Hide Internal Use Accounts"}
                id={"chkHideIAccount"}
                checked={hideTestCompanies}
                onChange={(e) => onHideInternalAccount(e.target.checked)}
                style={{ paddingTop: "13px" }}
              />
            </Form.Group>
          </Row>
        </Form>
        <div>
          <table className="table usersListTable">
            <thead>
              <tr>
                <th className="userGroupsCol" scope="col">
                  Company&nbsp;Name
                </th>
                <th className="userGroupsCol" scope="col">
                  Saleforce&nbsp;ID&nbsp;
                </th>
                <th className="userGroupsCol" scope="col">
                  Success&nbsp;Count
                </th>
                <th className="userGroupsCol" scope="col">
                  Total&nbsp;Count
                </th>
              </tr>
            </thead>
            <tbody>
              {gridData === undefined || gridData?.length === 0 ? (
                <tr>
                  <td
                    data-toggle="collapse"
                    colSpan={4}
                    className="usersLoadingArea text-center"
                  >
                    No matching records found
                  </td>
                </tr>
              ) : (
                gridData?.map((dat: any) => {
                  return (
                    <tr key={dat.companyId} className="userRow">
                      <td className="nameColData" title={dat.companyName}>
                        {dat.companyName}{" "}
                      </td>
                      <td>{dat.salesForceId}</td>
                      <td>{dat.successCount}</td>
                      <td>{dat.totalCount}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div>
          {gridData && gridData?.length > 0 && (
            <Pagination
              totalCount={count}
              pageIndex={pageNo}
              pageSize={pageSize}
              defaultPageSize={PAGESIZE}
              goToPage={onPageChange}
              sizePerPageList={SizePerPageList}
              showSizePerPageList={true}
              showGoToPages={true}
              showpageCount={true}
              ShowListAndGoto={true}
              pageResultCount={pageCount}
            />
          )}
        </div>
      </>
    </div>
  );
}
