import { Notification } from '../Common/Toaster'

export class ToasterHelper {
    static _instance: Notification;
    private constructor() { }
    public static get instance(): Notification {
        if (!ToasterHelper._instance) {
            console.log('new instance of AxiosDataService is created!!!');
            ToasterHelper._instance = new Notification();
        }
        return ToasterHelper._instance;
    }
}