import { IDataService } from "../DataAccess/Abstraction/IDataService";
import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';


export interface IConfigurationService {
    getApiNames(productType:string):any;
    getCompanies():any;
}

@injectable()
export class ConfigurationService implements IConfigurationService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.BASE_URL, 100);
    }

    getApiNames(productType:string):any{
        return this._dataService.get(`reports/apiConfiguration?productType=${productType}`);
    }

    getCompanies():any{
        return this._dataService.get(`company/all`)
    }
}

