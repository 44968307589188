import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationMenuDescription } from '../../Core/Domain/ViewModel/NavigationMenuDescription';
import { IUserMenuService } from '../../Core/Services/UserMenu/UserMenuService';
import container from '../../Startup/inversify.config';
import { TYPES } from '../../Startup/types';
import { IUserService } from '../../Core/Services/User/UserService';
import { SideMenuIconSelector, SideMenus } from '../../Assets/icons/IconCollection';
import { Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const userMenuService = container.get<IUserMenuService>(TYPES.IUserMenuService);
const userService = container.get<IUserService>(TYPES.IUserService);

interface UserMenuProps {
    open: boolean
};

export const UserMenu = (props:UserMenuProps)=> {
    const [openCloseToggle,setOpenCloseToggle] = useState(false);
    const [openMenu, setOpenMenu] = useState('');
    const [userName, setUserName] = useState('');
    const [userMenu, setUserMenu] = useState(new NavigationMenuDescription());
    const [userDataLoaded, setUserDataLoaded] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {

        userService.GetCurrentUser().then(function (response: any) {
            setUserName(response.data);
        })
            .catch(function (error: any) {
                
            });

        userMenuService.Load().then(function (response: any) {
            setUserMenu(response.data);
            setUserDataLoaded(!userDataLoaded);
        })
            .catch(function (error: any) {
                
            });
    }, []);

    const handleClick = (menu: any) => {
        setOpenMenu(menu);
        if(menu !== openMenu){
            setOpenCloseToggle(true);
        }else{
            setOpenCloseToggle(preState => { return !preState; });
        }
    };

    const getMenuIconEnum = (stringName: string): SideMenus => {

        switch (stringName) {
            case "build":
            case "usage":
                return SideMenus.RateLimit;
            case "event_note":
                return SideMenus.EndPointUsage;
            case "WebHookUsage":
                return SideMenus.WebHookUsage;
            default:
                return SideMenus.None;
        }
    }


    const getSubMenus = (menu: NavigationMenuDescription, index: number) => {
        return (
            menu.isParent == false ?
                <>{getListSubmenu(menu, index, true)}</>
                : 
                <>
                    <Tooltip title={!props.open ? menu.displayName : ''}>
                        <ListItemButton key={menu.name + index} onClick={() => handleClick(menu.name)}>
                            <ListItemIcon style={{ minWidth: "47px" }}>
                                <SideMenuIconSelector menuName={getMenuIconEnum(menu.icon)} />
                            </ListItemIcon>
                            <ListItemText disableTypography primary={menu.displayName} style={{ fontWeight: openMenu === menu.name ? '700' : '400' }} />
                            {openMenu === menu.name && openCloseToggle ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </Tooltip>
                    <Collapse in={openMenu === menu.name && openCloseToggle} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                menu.items.map((subMenu: NavigationMenuDescription, i: number) => {
                                    let returnValue: any;
                                    returnValue = (subMenu.items.length) === 0 ?
                                        getListSubmenu(subMenu, i) :
                                        getSubMenus(subMenu, i);
                                    return returnValue;
                                })
                            }
                        </List>
                    </Collapse>
                </>
        );
    }

    const getListSubmenu = (subMenu: NavigationMenuDescription, index: number, isMainMenu?: boolean) => {
        return (
            <Tooltip title={ !props.open ? subMenu.displayName : ''}>
                <ListItemButton component={Link} to={subMenu.target}
                    key={subMenu.name + index}
                    sx={{ 
                        bgcolor: (pathname === subMenu.target ? '#E6EBF0' : ''),
                        borderLeftColor: ( pathname === subMenu.target ? '#0973BA' : ''),
                        borderLeftWidth: ( pathname === subMenu.target ? '5px' : '' ),
                        borderLeftStyle: ( pathname === subMenu.target ? 'inset': '' ),
                        paddingLeft: 3
                        }} >
                    <ListItemIcon >
                        <FiberManualRecordIcon className='subMenu-icon'></FiberManualRecordIcon>
                    </ListItemIcon>
                    <ListItemText primary={subMenu.displayName} disableTypography style={{ fontWeight: pathname === subMenu.target ? '700': '400' }} />
                </ListItemButton>
            </Tooltip>
        );
    }


    return (
        <>
            <List
                sx={{ width: '100%', bgcolor: '#F0F1F1' }} 
                component="nav"
                aria-labelledby="nested-list-subheader" >
                {
                    userDataLoaded &&
                    userMenu.items?.map(
                        (menu: NavigationMenuDescription, index: number) => getSubMenus(menu, index)
                    )
                }
            </List>
        </>
    );
}