import { IDataService } from "../DataAccess/Abstraction/IDataService";
import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';

export interface IProductSubscriptionService {
    Load(pageNo:number, PageSize:number, searchText: string): any;
} 

@injectable()
export class ProductSubscriptionService implements IProductSubscriptionService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.BASE_URL, 10);
    }

    Load(pageNo:number, pageSize:number, searchText:string): any {
        return this._dataService.getPaged(1, `ProductSubscription/all?pageNo=${pageNo}&pageSize=${pageSize}&searchText=${searchText}`);
    }
}