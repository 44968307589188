import { IDataService } from "../DataAccess/Abstraction/IDataService";
import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';
import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import moment from 'moment';
import { SelectOptions } from "../../../Components/UIComponent/customDropdown";


export interface IEndpointUsageByEndpointService {
    Load(fromDate:Date, toDate:Date, productType : SelectOptions, company : SelectOptions,isTestCompany:boolean): any;
}

@injectable()
export class EndpointUsageByEndpointService implements IEndpointUsageByEndpointService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.BASE_URL, 100);
    }


    Load(fromDate:Date, toDate:Date, productType : SelectOptions, company: SelectOptions,isTestCompany:boolean): any {
            const fDate = fromDate==null?"01/01/0001": moment(fromDate).format('MM/DD/YYYY');
            const tDate = toDate==null?"01/01/0001":moment(toDate).format('MM/DD/YYYY');
            return this._dataService.get(`reports/endpoint?productType=${productType?.value??0}&companyId=${company?.value??0}&fromDate=${fDate??null}&toDate=${tDate??null}&isTestCompany=${isTestCompany}`);
        }
}