import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface ICustomDatepicker {
  selectedDate: Date | null | undefined;
  setSelectedDate: (selectedDate: Date | null | undefined) => void;
  handleOnclick?: () => void;
  dateFormat?: string;
  customInput?: any;
  placeholderText?: string;
  disabled?: boolean;
  className?: string;
  showMonthYearDate: boolean;
}

const CustomDatepicker: React.FC<ICustomDatepicker> = React.forwardRef<
  DatePicker,
  ICustomDatepicker
>((props, ref) => {
  const {
    selectedDate,
    setSelectedDate,
    handleOnclick,
    dateFormat,
    customInput,
    placeholderText,
    disabled,
    className,
    showMonthYearDate,
  } = props;

  const onChangeRaw = (
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    event?.preventDefault();
  };

  return (
      <DatePicker
        {...props}
        ref={ref}
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        onChangeRaw={onChangeRaw}
        placeholderText={placeholderText ?? "Select..."}
        customInput={customInput}
        dateFormat={dateFormat ?? "MM/dd/yyyy"}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
        maxDate={new Date()}
        disabled={disabled}
        showMonthYearPicker={showMonthYearDate}
        className={`${className} ${disabled ? "disabled_button" : ""} `}
      />
  );
});

export default CustomDatepicker;
